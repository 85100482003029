import { useOidc } from "@axa-fr/react-oidc";

export const useHandleUnauthorized = () => {
    const { logout } = useOidc();

    const handleUnauthorized = (res: any) => {
        if (
            (res?.status === 400 || res?.status === 401) &&
            res?.message === "Request failed with status code 401"
        ) {
            console.error('Unauthorized access - logging out');
            logout('/'); // Call logout function
        }
    };

    return { handleUnauthorized };
};