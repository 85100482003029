/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 4-Apr-2024
* @author      : Nandhakumar.R
* @since       : 0.0.1
* Requirement# :
* Purpose      : About page design
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1546          |  4/4/24    |   Nandha      |  About page ui design implementations
* 
*
*/

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Link, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './about.scss'
import { TransitionProps } from '@mui/material/transitions';
import logo from "../../assets/logo.gif";
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/system/Stack';
import { getVersionDetails } from '../../services/TenantService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import InfoIcon from '@mui/icons-material/Info';



export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;

}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const About: React.FC<any> = (props: SimpleDialogProps) => {
    const { onClose, open } = props;
    const { accessToken } = useOidcAccessToken();
    const [versionDetails, setVeresionDetails] = useState<any>();
    // //console.log(versionDetails)
    const handleClose = () => {
        onClose();
    };


    const getVersionDetail = async () => {
        const response = await getVersionDetails(accessToken);
        setVeresionDetails(response.data);
        //console.log(response);
    }
    useEffect(() => {
        getVersionDetail();
    }, [open])
    return (

        <Box paddingLeft={8} paddingRight={8}>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            disableEscapeKeyDown
        >
            <DialogTitle > <Box display="flex" alignItems="center">
                <Box flexGrow={1} >
                    <Stack direction={"row"} spacing={1}>
                     
                    {/* <Typography className='title' >About us</Typography> */}
                    </Stack>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon sx={{
                            '&:hover': {
                                color: 'red',
                            },
                        }} />
                    </IconButton>
                </Box>
            </Box></DialogTitle>
            <Box paddingLeft={8} paddingRight={8} >
                <Typography textAlign={'center'}>
                    <img src={logo} alt="Image" className="topBarLogo" />
                </Typography>
                <Typography textAlign={'center'} fontSize={'20px'} fontWeight={'400'} >
                    Graceful Management System
                </Typography>
            </Box>

            <DialogContent>
<DialogContentText>
<Stack direction="column" spacing={3}>
  {/* Title */}
  <Typography 
    variant="h2" 
    textAlign="center" 
    fontWeight="bold" 
    gutterBottom
  >
    About GMS Provider App
  </Typography>

  {/* Details Section */}
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <Typography 
        variant="body1" 
        fontWeight={600} 
      >
        Product Name:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body1" 
        fontWeight={500} 
      >
        GMS Provider App
      </Typography>
    </Grid>

    <Grid item xs={4}>
      <Typography 
        variant="body1" 
        fontWeight={600}
      >
        GMS Cloud Version:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body1" 
        fontWeight={500}
      >
        v0.7.0
      </Typography>
    </Grid>

    <Grid item xs={4}>
      <Typography 
        variant="body1" 
        fontWeight={600}
      >
       Provider API Version:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body1" 
        fontWeight={500}
      >
        v{versionDetails?.providerApiVersion || "N/A"}
      </Typography>
    </Grid>

    <Grid item xs={4}>
      <Typography 
        variant="body1" 
        fontWeight={600}
      >
        Provider Build Date:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body1" 
        fontWeight={500}
      >
        {versionDetails?.providerApiBuildDate || "N/A"}
      </Typography>
    </Grid>
  
  <Grid item xs={4}>
      <Typography 
        variant="body1" 
        fontWeight={600}
      >
         For Support & Queries:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body1" 
        fontWeight={500}
      >
        <Link href="mailto:noreply@GracefulManagement.com">
        noreply@GracefulManagement.com
      </Link>
      </Typography>
    </Grid>

    <Grid item xs={4}>
      <Typography 
        variant="body1" 
        fontWeight={600}
      >
         Phone:
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body1" 
        fontWeight={500}
      >
        262-649-4663
      </Typography>
    </Grid>
    </Grid>
</Stack>
</DialogContentText>
</DialogContent>
            <Divider/>
            <DialogActions style={{ justifyContent: "center" }}>
                <Typography variant="body2" color="text.secondary" align="center">
                    {'Copyright © 2020 - 2025 '}
                    <Link color="inherit" href="http://www.gracefulmanagement.com">
                        GMS Inc - All Rights Reserved
                    </Link>


                </Typography>
            </DialogActions>
        </Dialog>
    </Box >

    )
}

export default About;
