import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import RoofingIcon from '@mui/icons-material/Roofing';
import Questions from './components/Questions';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getTrades } from '../../services/TradeConfigurationServics';
import TradeForm from '../TradeForm';
import { useHandleUnauthorized } from '../../userSession/UserSession';



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const PermanentDrawerLeft: React.FC = () => {
    // const items = [
    //     { name: 'Roofing', subItems: ['Flat Roof', 'Slope Roof', 'Complex Roofing'] },
    //     { name: 'Siding', subItems: [] },
    //     { name: 'Guttering', subItems: [] },
    // ];
    const [value, setValue] = React.useState(0);
    const [showQuestionBuilder, setShowQuestionBuilder] = React.useState(false);
    const [openItem, setOpenItem] = React.useState<string | null>(null);
    const [selectedSubItem, setSelectedSubItem] = React.useState<number | null>(null);
    const [open, setOpen] = React.useState(false);
    const [tradeType, setTradeType] = React.useState<any>('');
    const [tradeName, setTradeName] = React.useState<any>('');
    const { accessToken } = useOidcAccessToken();
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [success, setSuccess] = React.useState<any>(false);
    const { handleUnauthorized } = useHandleUnauthorized();
    const closeTradeForm = async () => {
        setOpen(false);
        setShowQuestionBuilder(false);
        // setSelectedTradeData(undefined);
        // let result = await getTrades(accessToken);
        // setTradeData(result.data);
        getTradeList();
    }

    const tabhandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleShowQuestionTemplate = () => {
        setShowQuestionBuilder(true);
        console.log(showQuestionBuilder)
    }

    const handleClick = (itemName: string) => {
        setShowQuestionBuilder(false);
        setOpenItem(openItem === itemName ? null : itemName);
    };

    const handleOpen = () => {
        setOpen(true)
    };

    const getTradeList = async () => {
        let tradenames: any = [];
        let result = await getTrades(accessToken);
        handleUnauthorized(result)
        setTradeData(result.data);
        ////console.log(result.data)

        result?.data?.map((data) => {
            tradenames.push(data?.name)
        })
        setOpenItem(tradenames[tradenames.length - 1]);
        // setTradeNames(Array.from(new Set(tradenames)));
        // const response = await getTemplates(accessToken);
        // setTemplateList(response.data);
    }
    React.useEffect(() => {
        getTradeList();
        // if (openItem == null || openItem === undefined) {
        //     setOpenItem('Roofing')
        // }
    }, [success]);

    return (
        <Box sx={{
            display: 'flex', border: '1px solid #e7e7e7' // Stack elements vertically
        }}>
            <Box sx={{ minWidth: 280, height: '100vh', borderRight: '1px solid #e7e7e7' }}>
                <Box className='tradeDescription boxShadow' sx={{ backgroundColor: '#e7e7e7', padding: 2, color: 'color' }}>
                    <Typography variant="h6">Trade Lists</Typography>
                </Box>
                <Divider />
                <List sx={{ padding: 0, margin: 0 }}>
                    {tradeData.map((item) => (
                        <React.Fragment key={item.name}>
                            <ListItem
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                    pl: 0,
                                    backgroundColor: openItem === item.name ? '#3c5bfa' : 'inherit', // Highlight selected item
                                    '&:hover': {
                                        backgroundColor: '#d0d0d0',
                                    },
                                    color: openItem === item.name ? '#fff' : 'inherit'
                                }}
                            >
                                <ListItemButton onClick={() => handleClick(item.name)}>
                                    <ListItemIcon>
                                        <RoofingIcon sx={{
                                            padding: 0,
                                            margin: 0,
                                            pl: 0,
                                            backgroundColor: openItem === item.name ? '#3c5bfa' : 'inherit', // Highlight selected item
                                            '&:hover': {
                                                backgroundColor: '#d0d0d0',
                                            },
                                            color: openItem === item.name ? '#fff' : 'inherit'
                                        }} />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                    {openItem === item.name}
                                </ListItemButton>
                            </ListItem>
                            {/* <Collapse in={openItem === item.name} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx={{ padding: 0, margin: 0 }}>
                                    {item.subItems.map((subItem, index) => (
                                        <React.Fragment key={index}>
                                            <Divider />

                                            <ListItem
                                                sx={{
                                                    pl: 4,
                                                    backgroundColor: selectedSubItem === index ? '#3c5bfa' : 'inherit', // Highlight selected item
                                                    '&:hover': {
                                                        backgroundColor: '#d0d0d0',
                                                        color: 'white'// Background color on hover
                                                    },
                                                    padding: 1, margin: 0,
                                                    color: selectedSubItem === index ? '#fff' : 'inherit'
                                                }}
                                            >
                                                <ListItemButton onClick={() => handleSubItemClick(index)}>
                                                    <ListItemText primary={subItem} sx={{ padding: 0, margin: 0 }} />
                                                </ListItemButton>
                                            </ListItem>
                                            {index < item.subItems.length - 1 && <Divider />} 
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Collapse> */}
                            <Divider /> {/* Separator */}
                        </React.Fragment>
                    ))}
                </List>

            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e7e7e7'
                }}
            >
                {/* Header */}

                <>   <Grid
                    container
                    sx={{ p: 0 }}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12} md={12} className='m-2'>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ width: '100%' }} // Ensure Stack takes full width
                        >
                            <Typography
                                variant="h5" // Adjust the variant as needed
                                sx={{ flexGrow: 1, textAlign: 'center' }} // Center text
                            >
                                {
                                    openItem
                                        ? <>Trade Description: {openItem}</>
                                        : <>Your trades are empty, please add your trades</>
                                }
                            </Typography>
                            <Box>
                                <Button variant="contained" onClick={handleOpen}>
                                    Add New Trade
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                    <Divider />
                    <>
                        <Box sx={{ bgcolor: 'background.paper', borderBottom: '1px solid #e7e7e7' }}>
                            <Tabs
                                value={value}
                                onChange={tabhandleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {/* <Tab className='tradeTab ' sx={{ textTransform: "none", }} label="Add/Edit Trade" /> */}
                                <Tab
                                    className='tradeTab'
                                    sx={{
                                        textTransform: 'none',
                                        color: value === 0 ? 'white' : '#3c5bfa', // Text color based on selection
                                        bgcolor: value === 0 ? '#3c5bfa' : 'white', // Background color based on selection
                                        '&.Mui-selected': {
                                            color: 'white', // Text color when selected
                                            bgcolor: '#3c5bfa !important', // Background color when selected
                                        },
                                        '&:hover': {
                                            bgcolor: value !== 0 ? '#f0f0f0' : 'inherit', // Hover background color for non-selected tabs
                                        },
                                    }}
                                    label="Question Builder"
                                />
                                {/* <Tab
                                    className='tradeTab'
                                    sx={{
                                        textTransform: 'none',
                                        color: value === 1 ? 'white' : '#3c5bfa',
                                        bgcolor: value === 1 ? '#3c5bfa' : 'white',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            bgcolor: '#3c5bfa !important',
                                        },
                                        '&:hover': {
                                            bgcolor: value !== 1 ? '#f0f0f0' : 'inherit',
                                        },
                                    }}
                                    label="Contract Settings"
                                /> */}
                                {/* <Tab className='tradeTab' sx={{ textTransform: "none" }} label="BOM Settings" /> */}
                            </Tabs>
                        </Box>
                    </>
                    <Typography>{showQuestionBuilder}</Typography>
                    {open && <TradeForm open={open} function={closeTradeForm} handleShowQuestionTemplate={handleShowQuestionTemplate} setTradeName={setTradeName} setTradeType={setTradeType} />}
                    {showQuestionBuilder && (
                        <Questions templateQuestionData={tradeName} tradeTypeName={tradeType} />
                    )}
                    <>
                        {!showQuestionBuilder && (
                            <>

                                <>
                                    <Grid
                                        container
                                        sx={{ p: 0 }}
                                        spacing={{ xs: 2, md: 3 }}
                                        columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                        <Grid item xs={12} md={12} >
                                            <CustomTabPanel value={value} index={0}>
                                                <Box>
                                                    {openItem !== undefined && openItem !== null ? (
                                                        <Questions templateQuestionData={openItem} />
                                                    ) : (
                                                        <Questions templateQuestionData='' />
                                                    )}
                                                </Box>
                                            </CustomTabPanel>
                                        </Grid>
                                        {/* <Grid item xs={12} md={12} >
                                            <CustomTabPanel value={value} index={1}>
                                                <Tradecontract />
                                            </CustomTabPanel>
                                        </Grid> */}
                                    </Grid>
                                </>
                                {/* <Tradeconfiguration /> */}
                            </>
                        )}
                    </>

                </>
            </Box>
        </Box>
    );
};

export default PermanentDrawerLeft;
