import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";
// import AppBar from "@mui/material/AppBar";
import {
  Box, Divider, IconButton, List, ListItem, ListItemButton,
  ListItemText, Toolbar, Typography, Button,
  InputBase, MenuItem, Menu, Tooltip, Badge, Avatar
} from "@mui/material";
// import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";

import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  useGlobalContext,
  tokens,
  themeSettings,
  useGlobalSidebarContext,
  CollapseMode,
} from "../hooks/theme";
import { createTheme } from "@mui/material/styles";
// import { useProSidebar } from "react-pro-sidebar";
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Stack } from "@mui/system";
import GroupsIcon from '@mui/icons-material/Groups';
import { useOidcAccessToken, useOidc } from "@axa-fr/react-oidc";
import './Topbar.scss';
import logo from "../assets/GmsLogo.png";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import createbidicon from "../assets/imgs/Bid-Design.svg";
import makepayment from "../assets/imgs/payment.svg";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import About from "../pages/About templates/about";
import { useHandleUnauthorized } from "../userSession/UserSession";

interface Props {
  window?: () => Window;
}
type ItemProps = {
  title: string;
  to: string;
  icon: React.ReactNode;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  render?: boolean;
  disabled?: boolean; // Make disabled prop optional
};

const drawerWidth = 290;
const navItems = ["Home", "About", "Contact"];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const TopAppBar: React.FC = (props: Props) => {

  const { accessTokenPayload } = useOidcAccessToken();
  const { logout } = useOidc();
  const { theme } = useGlobalContext();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { handleUnauthorized } = useHandleUnauthorized();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openAbout, setOpenAbout] = React.useState(false);
  const [openCart, setOpenCart] = React.useState(false);
  const [count, setCount] = React.useState();
  const [userDetails, setUserDeatils] = useState();
  const componentData = useRef<any>();
  const [openPayList, setOpenPayList] = useState(false);
  const [tenantDetails, setTenantDetails] = useState<any>();
  const [unPaid, setUnPaid] = useState<any[]>([]); // Initialize with an empty array

  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);

  const generalConfig = () => {

  };

  const handleCartOpen = () => {
    setOpenCart(true);
  };

  const createBid = () => {
    navigate("/createbid", {

    })
  }
  const handleCartClose = () => {
    setOpenCart(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>

  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleAboutOpen = () => {
    setOpenAbout(true);
  };
  const handleAboutClose = () => {
    setOpenAbout(false);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNextPage = () => {
    navigate('/assetList');
  };

  const handleTenant = () => {
    navigate('./Tenantconfiguration');
  };

  const renderMenu = (
    // <Menu
    //   anchorEl={anchorEl}     
    //   id={menuId}
    //   keepMounted    
    //   anchorOrigin={{
    //     vertical: 'bottom',
    //     horizontal: 'center',
    //   }}
    //   transformOrigin={{
    //     vertical: 'top',
    //     horizontal: 'center',
    //   }}
    //   open={isMenuOpen}
    //   onClose={handleMenuClose}
    // >
    //   <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    //   <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
    // </Menu>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem onClick={handleClose}>
        <Avatar /> Profile
      </MenuItem>
      <MenuItem onClick={generalConfig}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem> */}
      <MenuItem onClick={() => logout('/')}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem
        onClick={() =>
          setThemeState(theme.palette.mode === "dark" ? "light" : "dark")
        }
      >
        <ListItemIcon>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon fontSize="small" />
          ) : (
            <DarkModeOutlinedIcon fontSize="small" />
          )}
        </ListItemIcon>
        {theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}       
      </MenuItem> */}
      <MenuItem>
        <ListItemIcon>
          <Badge badgeContent={4} color="error" >
            <MailIcon fontSize="small" />
          </Badge>
        </ListItemIcon>
        Messages
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <NotificationsIcon fontSize="small" />
        </ListItemIcon>
        Notifications
      </MenuItem>
      {/* <MenuItem >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />        
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        Profile
      </MenuItem>
      <MenuItem onClick={generalConfig}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={() => logout()}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );


  const { setCollapse } = useGlobalSidebarContext();

  const [openSidebar, setOpen] = React.useState(true);

  const [subMenuOpen, setSubMenuOpen] = React.useState(true);

  let selected = location.pathname;;
  async function setSelected(path: any) {
    selected = location.pathname;
    navigate(path);
  }

  const handleDrawerOpenClose = () => {
    setOpen(!openSidebar);
    // if (openSidebar) {
    //   setOpen(false);
    // } else {
    //   setOpen(true);
    // }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const textWithIcon = (icon: any | null, title: any | null) => {
    return (<>
      <Stack direction="row" spacing={4}>
        <p>{icon}</p><p className="ps-2">{title}</p>
      </Stack>
    </>);
  };
  const invoicePage = () => {
    // Assuming you're using React Router's `useNavigate` hook for navigation

    // Navigate to the "Invoice" tab by updating the URL with the corresponding tab index
    navigate("/projectinformation?tab=7");
  };
  const Item = ({
    title,
    to,
    icon,
    selected,
    setSelected,
  }: ItemProps) => {
    return (
      // <ListItem key={title} disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        selected={selected.includes(to)}
        // disabled={tenantDetails == undefined ? true : false}
        onClick={() => setSelected(to)}
        sx={{

          "&.MuiListItemButton-root": {
            marginRight: 1,
            marginLeft: 0,
            marginBottom: 1,
            marginTop: 1,
            borderRadius: 18,


            WebkitBorderTopLeftRadius: 0,
            WebkitBorderBottomLeftRadius: 0,
            // background: 'linear-gradient(to right, #8aa6fd, #3c5bfa, #001a7a)',
          },
          "&.Mui-selected": {
            backgroundColor: '#3c5bfa !important',
            color: colors.white[700]
          },
          "&:hover": { // Changed from :hover to &:hover
            backgroundColor: '#3c5bfa',
            color: colors.white[700]
          },



        }}
      >
        {/* <ListItemIcon sx={{
          "&.MuiListItemIcon-root": {
            minWidth: 0,
            marginRight: 3.1,
          }        
        }}>
          {icon}
        </ListItemIcon> */}
        <ListItemText primary={textWithIcon(icon, title)} sx={{ whiteSpace: "nowrap" }} >
          {/* {icon} */}
        </ListItemText>
      </ListItemButton >
      // </ListItem>
    );
  };

  const tenantItemPropsList: ItemProps[] = [
    {
      title: "Tenant",
      to: "/tenant",
      icon: <DashboardIcon />,
      selected: selected,
      setSelected: setSelected,
      render: true
    },
    {
      title: "Trade Configuration",
      to: "/tradeconfiguration",
      icon: <BarChartIcon />,
      selected: selected,
      setSelected: setSelected,
      render: true
    },  
    {
      title: "Asset Questions",
      to: "/assettemplate",
      icon: <HomeOutlinedIcon />,
      selected: selected,
      setSelected: setSelected,
      render: true
    },  
    {
      title: "Category",
      to: "/categorymaster",
      icon: <HomeOutlinedIcon />,
      selected: selected,
      setSelected: setSelected,
      render: true
    },  
    {
      title: "Trade Lists",
      to: "/tradelist",
      icon: <HomeOutlinedIcon />,
      selected: selected,
      setSelected: setSelected,
      render: true
    }
  ];


  let itemPropsList: ItemProps[];
  itemPropsList = tenantItemPropsList;

  return (
    <Box component="nav" >
      <AppBar component="nav" sx={{ background: colors.gradient[2] }}>
        <Toolbar>
          <IconButton
            // onClick={() => setCollapseMode(!isCollapsed)}
            onClick={handleDrawerOpenClose}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>

          <img src={logo} onClick={handleAboutOpen} alt="Image" className="topBarLogo" />
          <About
            open={openAbout}
            onClose={handleAboutClose}
          />
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>



            <Box sx={{ display: { xs: "none", md: "flex" }, paddingRight: '10px', marginTop: "7px" }}>
              <Stack className="mr-2" direction={"row"}>
              </Stack>
            </Box>
            <IconButton size="large" color="inherit">
              <MailIcon />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
            >
              <Badge color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 0 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32, backgroundColor: "#a23cff" }}>{accessTokenPayload.name[0]}</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Box component="nav" >
        <Drawer

          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,

            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* Sidebar */}
      <Drawer variant="permanent" open={openSidebar} sx={{
        "& .MuiDrawer-paper": {
          // background-color: #7b7b7b00;
          backgroundColor: "#7b7b7b00",
          borderRight: "none"
        },
      }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding
            sx={{ display: 'block' }} >

{itemPropsList
          .filter((item) => item.render)
          .map((item) => (
            <div key={item.title}>
              
                <Item
                  title={item.title}
                  to={item.to}
                  icon={item.icon}
                  selected={item.selected}
                  setSelected={item.setSelected}
                />
           
            </div>
          ))}
            
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

export default TopAppBar;