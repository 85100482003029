
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { visuallyHidden } from '@mui/utils';
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, IconButton, InputBase, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box/Box';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Header from './Header';
import { getAllTenantcount } from '../services/TenantService';
import { deleteCategory, getAllCategoryMaster, getCategoryMaster } from '../services/CategoryService';
import AddCategory from './AddCategory';
import { useHandleUnauthorized } from '../userSession/UserSession';



interface Data {
    id: number;
    categoryName: string;
    // invalid: string;

}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Category Id',
    },
    {
        id: 'categoryName',
        numeric: true,
        disablePadding: false,
        label: 'Category Name',
    },

    // {
    //     id: 'invalid',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'invalid',
    // }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead >
            <TableRow className='tableHead'>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel

                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell>Action</TableCell>
            </TableRow>
        </TableHead>
    );
}

const Tenant = () => {
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
    const [openUpdateDialo, setOpenUpdateDialog] = React.useState<boolean>(false);

    const [createSuccess, setCreateSuccess] = React.useState<any>(false);
    const [error, setError] = React.useState<any>(false);
    const [noChange, setNoChange] = React.useState(false);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [rows, setRows] = React.useState<any>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const [count, setCount] = React.useState(Number);
    const [openAddCategory, setOpenAddCategory] = React.useState(false);
    const [row, setRow] = React.useState<any>([]);
    const { handleUnauthorized } = useHandleUnauthorized();

    const handleOpenConfirmation = () => {
        setOpenAddCategory(true);
        setRow(undefined)
    };
    // Inside the parent component
    const handleCloseCategory = () => {
        setOpenAddCategory(false);
    };

    const handleEditCustomer = (row: any) => {
        setRow(row);
        setOpenAddCategory(true);
    };
    const handlegetCategory = (row: any) => {
        setRows(row);
    };
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleDialogClose = () => {
                fetchData();
        setOpenDialog(false);
    };
    const handleDeleteCategory = (id) => {
        deleteCategory(id, accessToken).then((res) => {
            setOpenDialog(true)
            if (res.ok === true && res.status === 200 || res.status === 201) {
                setCreateSuccess(true);

                setError(false);

            }
            else {
                setCreateSuccess(false);
                setError(true);

            }
        })
    }


    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const onNextPage = () => {
        navigate("/vendorregistration")
    }
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const fetchProject = async () => {
        try {
            const res = await getAllTenantcount(accessToken);
            setCount(res.data);
            // const response = await getAllTenants("",accessToken, page, rowsPerPage);
            // console.log(response);
            // setRows(response.data);
        } catch (error: any) {

        } finally {

        }
    };

    useEffect(() => {
        //    fetchProject();
        fetchData()
    }, [page, rowsPerPage]);

    let navigate = useNavigate();

    // const inputchange = async (e: any) => {
    //     var value = e.target.value === "" ? null : e.target.value;
    //     if(e.target.value.length >= 3){
    //       fetchData(value);
    //     }
    //     if(e.target.value.length === 0){
    //       fetchData(value);
    //     }
    //   };


    const fetchData = async () => {
        const res = await getAllCategoryMaster(accessToken, page, rowsPerPage);
        handleUnauthorized(res)
        setRows(res.data)
        //   const response = await getAllTenants(searchParams,accessToken, page, rowsPerPage);
        //   console.log(response);
        //   setRows(response.data);
    };


    return (
        // <div >
        <>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleClose} security='success' sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            >
                <Header title="Category List" subtitle="" />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                            <div className='searchBar'>
                                <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }} justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            onClick={handleOpenConfirmation}
                                            sx={{ marginRight: '13px' }}
                                        >
                                            <AddOutlinedIcon className='personIcon' />&nbsp; Create Category
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/* <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                                    <Grid item xs={6} > <Paper
                                        variant="outlined"
                                        component="form"
                                        className='searchField'
                                        sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Search by tenantname or tenantId"
                                            inputProps={{ "aria-label": "search google maps" }}
                                          onChange={(e) => inputchange(e)}
                                        />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                                        </IconButton>
                                    </Paper>
                                    </Grid> */}
                                {/* <Grid item xs={6} className='alignEnd' sx={{ padding: '15px' }}>
                                        <Button
                                            variant="contained" size="large"
                                            onClick={handleOpenConfirmation}
                                        >
                                            <AddOutlinedIcon className='personIcon' />&nbsp; Create Tenant
                                        </Button>
                                    </Grid>
                                </Grid> */}
                            </div>
                            <TableContainer sx={{ marginTop: 3 }}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows?.length}
                                    />

                                    <TableBody>
                                        <>
                                            {rows?.length === 0 ?
                                                (<TableRow>
                                                    <TableCell colSpan={10}>
                                                        <Typography align="center">No Category details</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                ) : (
                                                    <>{rows?.map((row: any, index: any) => {
                                                        const isItemSelected = isSelected(row.id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return (

                                                            <TableRow
                                                                hover
                                                                onClick={(event) => handleClick(event, Number(row?.id))}
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                selected={isItemSelected}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                                <TableCell padding="checkbox" size='small'>
                                                                    <CustomCheckbox
                                                                        className='check'
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    size='small'
                                                                    // className='tablerows'
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    {row?.id}
                                                                </TableCell>
                                                                <TableCell size='small' >{row?.name}</TableCell>
                                                                {/* <TableCell size='small'>{row?.innvalid}</TableCell> */}


                                                                <TableCell size='small'>
                                                                    <Stack direction={'row'}>
                                                                        {/* {(row.status === 'invited' || !row.status) && ( */}
                                                                        <Tooltip title="Edit">
                                                                            <IconButton onClick={() => handleEditCustomer(row)}>
                                                                                <EditRoundedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {/* )} */}
                                                                        {/* {row.status === 'Active' && (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={() => onEdit(row.id)}>
                                      <EditRoundedIcon />   
                                    </IconButton>
                                  </Tooltip>
                                )} */}
                                                                        <Tooltip title="Delete">

                                                                            <IconButton onClick={()=>handleDeleteCategory(row.id)}  >
                                                                                <DeleteRoundedIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Stack>

                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    )
                                                    }

                                                    </>)}

                                        </>
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                // columnsPerPage={columns}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
            {openAddCategory && <AddCategory open={openAddCategory} onClose={handleCloseCategory} setRows={setRows} row={row} />}
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}

            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        {/* <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography> */}
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Are you sure?
                        </Typography>
                        <Typography textAlign={'center'}>
                            You won't be able to revert this!
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
                    <Button onClick={handleDialogClose}
                        // onClick={() => onDeleteVendor(vendorId)}
                        autoFocus className="primaryTonalButton">
                        Yes, Delete it!
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}

export default Tenant;
